@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

* {
	padding: 0px;
	margin: 0px;
}
body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
	padding-left: 0.4rem;
}

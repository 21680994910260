.notif-enter {
  transform: translate(+350px);
}
.notif-enter-active {
  transform: translate(0px);
  transition: all 500ms ease-in;
}
.notif-exit {
  opacity: 1;
}
.notif-exit-active {
  transform: translate(+350px); 
  transition: all 500ms ease-in;
}
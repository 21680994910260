.dataRange {
	height: 40px !important;
	width: 220px !important;
	border-radius: 12px !important;
	border-color: #dbdbdb !important;
	background-color: transparent !important;
}

.dataRangeActive {
	height: 40px !important;
	width: 220px !important;
	border-radius: 12px !important;
	border-color: #5222d0 !important;
}
.dataRangeActive:hover {
	border-color: #5222d0 !important;
	color: #5222d0 !important;
}

.dataRangeActive .ant-picker-separator {
	color: #5222d0 !important;
}

.dataRangeActive .ant-picker-input > input {
	color: #5222d0 !important;
	/* font-family: 'Montserrat' !important; */
	/* font-style: normal !important; */
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px !important;
}
.dataRange:hover {
	border-color: #5222d0;
	color: #5222d0;
}
.ant-picker-cell .ant-picker-cell-inner {
	padding: 0px !important;
	height: 36px;
	width: 36px !important;
	border: 1px solid rgba(127, 127, 132, 0.5) !important;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-picker-body {
	padding: 8px 12px 32px 12px;
}
.ant-picker-disabled::hover {
	border-color: #7f7f84;
	color: #7f7f84;
}
.ant-picker-focused {
	border-color: #5222d0 !important;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08) !important;
	border: none;
}

.ant-picker-dropdown {
	z-index: 10000 !important;
}

.ant-picker-dropdown-range {
	z-index: 10000 !important;
}

.ant-picker-focused .ant-picker-input > input {
	color: #5222d0 !important;
	/* font-family: 'Montserrat' !important; */
	/* font-style: normal !important; */
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px !important;
}

.ant-picker-focused .ant-picker-separator {
	color: #5222d0;
	padding: 0px !important;
}

.dataRange:hover .ant-picker-separator {
	border-color: #5222d0;
	color: #5222d0;
	padding: 0px;
}

.ant-picker-range-separator {
	position: relative !important;
	left: -10px !important;
	padding: 0px !important;
}
.ant-picker-range:hover {
	border-color: #5222d0;
	color: #5222d0;
}
.ant-picker .ant-picker-input,
.ant-picker.ant-picker-range {
	display: flex !important;
	flex-direction: row !important;
}

.ant-picker-suffix {
	order: -1 !important;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
	margin-left: 0 !important;
	margin-right: 2px !important;
}

.ant-picker-panel-container {
	border-radius: 12px !important;
}

.ant-picker-input > input {
	color: #7f7f84 !important;
	/* font-family: 'Montserrat' !important; */
	/* font-style: normal !important; */
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px !important;
}

.dataRange:hover .ant-picker-input > input {
	border-color: #5222d0;
	color: #5222d0;
	/* font-family: 'Montserrat' !important; */
	/* font-style: normal !important; */
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px !important;
}

.ant-picker-range .ant-picker-active-bar {
	background: #5222d0 !important;
	width: 65px !important;
	margin-left: 33px !important;
}

.ant-picker-header-view button:hover {
	color: #5222d0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
		.ant-picker-cell-in-range
	):after {
	border-top: none !important;
	border-bottom: none !important;
}
.ant-picker-date-panel
	.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
	.ant-picker-cell-inner:after {
	background: transparent !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
		.ant-picker-cell-in-range
	):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after {
	border-top: none !important;
	border-bottom: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after {
	border-left: none !important;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
	border-right: none !important;
}

tr
	> .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after {
	border-right: none !important;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
	border-left: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
	background: rgba(82, 34, 208, 0.24) !important;
	height: 36px !important;
	margin-left: 8px !important;
	width: 36px !important;
	border-radius: 8px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
	background: #5222d0 !important;
	border-radius: 8px !important;
	height: 36px;
	width: 36px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border: 1px solid #5222d0 !important;
	border-radius: 8px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: #5222d0 !important;
	border-radius: 8px !important;
}
.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
	background: rgba(82, 34, 208, 0.24) !important;
	height: 36px !important;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
	background: rgba(82, 34, 208, 0.4) !important;
	height: 36px !important;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
	background-color: rgba(82, 34, 208, 0.24) !important;
	height: 36px !important;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
	background-color: rgba(82, 34, 208, 0.24) !important;
	height: 36px !important;
}

.ant-picker-date-panel
	.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
	.ant-picker-cell-inner:after {
	background-color: rgba(82, 34, 208, 0.24) !important;
	height: 36px !important;
	margin-right: 8px !important;
	width: 36px !important;
	border-radius: 8px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
		.ant-picker-cell-range-end-single
	)::before {
	background-color: transparent !important;
	height: 36px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
		.ant-picker-cell-range-start-single
	)::before {
	background-color: transparent !important;
	height: 36px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
		.ant-picker-cell-range-end-single
	).ant-picker-cell-range-hover-end::before {
	background-color: transparent !important;
	height: 36px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
		.ant-picker-cell-range-start-single
	).ant-picker-cell-range-hover-start::before {
	background-color: transparent !important;
	height: 36px !important;
}

.ant-picker-header-view {
	order: -1 !important;
}

.ant-picker-header-super-prev-btn {
	display: none !important;
}

.ant-picker-header-super-next-btn {
	display: none !important;
}

.ant-picker-header-view {
	margin-left: 25px !important;
}

.groupDate {
	height: 40px;
	width: 225px;
	border-radius: 12px;
	background-color: transparent;
	border-color: #dbdbdb !important;
}

.groupDate:hover {
	border-color: #5222d0 !important;
}

.groupDate:hover .ant-picker-input > input {
	color: #5222d0 !important;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
}

.ant-picker-input > input::placeholder {
	color: #7f7f84;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
}

.ant-picker-cell {
	padding: 8px;
}

.ant-picker-date-panel {
	padding: 8px 12px 32px 12px;
}

.ant-picker-body {
	padding: 8px 12px 32px 12px;
}

.ant-picker-today-btn {
	color: #5222d0 !important;
}

.ant-picker-date-panel {
	display: flex;
	flex-direction: column;
	width: 400px;
}
.ant-picker-cell .ant-picker-cell-inner {
	width: 36px;
	height: 36px;
	border: 1px solid rgba(127, 127, 132, 0.5) !important;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.ant-picker-date-panel .ant-picker-content {
	width: 365px;
}

td.ant-picker-cell.ant-picker-cell-in-view {
	padding: 8px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	background: #5222d0 !important;
	border-radius: 4px;
}

td.ant-picker-cell.ant-picker-cell-disabled {
	color: rgba(0, 0, 0, 0.5) !important;
	pointer-events: none;
	height: 36px;
}

.ant-picker-cell-disabled:before {
	height: 36px;
	background-color: transparent;
}

#headQty .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {
	top: -25px !important;
	overflow: visible !important;
	border: none;
	background: none;
}
#headQty .apexcharts-tooltip {
	overflow: visible !important;
	border: none;
	box-shadow: none;
}
#headQty .apexcharts-tooltip {
	border: none;
	background: none;
}
#headQty .apexcharts-theme-light {
	border: none;
	background: none;
}
#headQty .apexcharts-xaxis-tick {
	transform: translateY(-176px);
	stroke: #dbdbdb;
}
#headQty .apexcharts-grid line {
	stroke: #dbdbdb;
}
#headQty .apexcharts-grid-borders line:first-child {
	stroke: transparent;
}
.apexcharts-xaxis-label {
	font-weight: 400;
}

#foodtable .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {
	top: -90px !important;
	border: none;
	background: none;
	pointer-events: auto;
	z-index: 12;
}

#foodtable .apexcharts-tooltip {
	top: -100px !important;
	pointer-events: initial !important;
	z-index: 12;
}
#foodtable .apexcharts-xaxis-texts-g > text {
	fill: #fff;
}
#foodtable .apexcharts-point-annotations {
  cursor: pointer !important;
}

.apexcharts-yaxis-label {
	fill: #7f7f84;
}

.apexcharts-xaxis-label {
	fill: #7f7f84;
}

#dashboardChart .apexcharts-xaxis-label {
	fill: #000;
}

#dashboardChart .apexcharts-gridlines-vertical {
	opacity: 0;
}

#dashboardChart .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {
	top: -80px !important;
	overflow: visible !important;
	border: none;
	background: none;
}
#dashboardChart .apexcharts-tooltip {
	overflow: visible !important;
	border: none;
}
#dashboardChart .apexcharts-tooltip {
	border: none;
	background: none;
}
#dashboardChart .apexcharts-theme-light {
	border: none;
	background: none;
}
#dashboardChart .apexcharts-xaxis-tick {
	opacity: 0;
}

#foodtable .apexcharts-tooltip {
	overflow: visible !important;
	border: none;
	box-shadow: 0px 0px 16px 0px rgba(4, 6, 6, 0.16);
}
#foodtable .apexcharts-tooltip {
	border: none;
	background: none;
}

#lineHeadChart .apexcharts-xaxis-tick {
	transform: translateY(-176px);
	stroke: #dbdbdb;
}

#lineHeadChart .apexcharts-series > path > .apexcharts-line {
	stroke: #000;
	z-index: 1000000 !important;
	display: block;
}

#lineHeadChart .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {
	top: -95px !important;
	overflow: visible !important;
	border: none;
	background: none;
}
#lineHeadChart .apexcharts-tooltip {
	overflow: visible !important;
	border: none;
	box-shadow: none;
}
#lineHeadChart .apexcharts-tooltip {
	border: none;
	background: none;
}
#lineHeadChart .apexcharts-theme-light {
	border: none;
	background: none;
}

#consuptionChart .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {
	top: -80px !important;
	overflow: visible !important;
	border: none;
	background: none;
}
#consuptionChart .apexcharts-tooltip {
	overflow: visible !important;
	border: none;
	box-shadow: none;
}
#consuptionChart .apexcharts-tooltip {
	border: none;
	background: none;
}
#consuptionChart .apexcharts-theme-light {
	border: none;
	background: none;
}
#consuptionChart .apexcharts-xaxis-tick {
	transform: translateY(-176px);
	stroke: #dbdbdb;
}
#consuptionChart .apexcharts-grid line {
	stroke: #dbdbdb;
}
#consuptionChart .apexcharts-grid-borders line:first-child {
	stroke: transparent;
}

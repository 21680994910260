#lamenessChart .apexcharts-xaxis-tick {
	transform: translateY(-176px);
	stroke: #dbdbdb;
}

#lamenessChart .apexcharts-series > path > .apexcharts-line {
	stroke: #000;
	z-index: 1000000 !important;
	display: block;
}

#lamenessChart .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {
	top: -120px !important;
	overflow: visible !important;
	border: none;
	background: none;
}
#lamenessChart .apexcharts-tooltip {
	overflow: visible !important;
	border: none;
	box-shadow: none;
}
#lamenessChart .apexcharts-tooltip {
	border: none;
	background: none;
}
#lamenessChart .apexcharts-theme-light {
	border: none;
	background: none;
}

#fatnessChart .apexcharts-xaxis-tick {
	transform: translateY(-176px);
	stroke: #dbdbdb;
}

#fatnessChart .apexcharts-series > path > .apexcharts-line {
	stroke: #000;
	z-index: 1000000 !important;
	display: block;
}

#fatnessChart .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {
	top: -90px !important;
	overflow: visible !important;
	border: none;
	background: none;
}
#fatnessChart .apexcharts-tooltip {
	overflow: visible !important;
	border: none;
	box-shadow: none;
}
#fatnessChart .apexcharts-tooltip {
	border: none;
	background: none;
}
#fatnessChart .apexcharts-theme-light {
	border: none;
	background: none;
}

#yieldQtyChart .apexcharts-xaxis-tick {
	transform: translateY(-176px);
	stroke: #dbdbdb;
}

#yieldQtyChart .apexcharts-series > path > .apexcharts-line {
	stroke: #000;
	z-index: 1000000 !important;
	display: block;
}

#yieldQtyChart .apexcharts-tooltip.apexcharts-active.apexcharts-theme-light {
	top: -35px !important;
	overflow: visible !important;
	border: none;
	background: none;
}
#yieldQtyChart .apexcharts-tooltip {
	overflow: visible !important;
	border: none;
	box-shadow: none;
}
#yieldQtyChart .apexcharts-tooltip {
	border: none;
	background: none;
}
#yieldQtyChart .apexcharts-theme-light {
	border: none;
	background: none;
}

.carousel {
  margin-top: 30px;
}

.carousel-slider {
	width: 70% !important;
}

.carousel-wrapper img {
  width: 100%;
  height: auto;
}
.thumbs-wrapper {
	margin: 0px !important;
	}

.thumbs-wrapper ul {
	padding: 0px !important;
	margin: 10px 0px 0px 0px !important;
}
.thumbs-wrapper > ul > .selected {
	border: none !important;
	border-radius: none;
}
.thumbs-wrapper > ul > .selected > div > img {
	border: 2px solid #5222D0 !important;
	padding: 2px !important;
	border-radius: 14px !important;
}

.thumbs-wrapper > ul > li > div > img {
	border: none;
	border-radius: 14px !important;
}

.thumb {
	padding: 0px !important;
	border: none !important;
	border-radius: none;
}
.thumb:hover {
	cursor: pointer;
	padding: 0px !important;
	border: none !important;
	border-radius: none;
}
